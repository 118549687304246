import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import SEO from "../components/common/SEO/";
import Hero from "../components/common/Hero/HomeHero";
import Sponsor from "../components/Sponsor";
import Video from "../components/Home/Video";
import ValueProposition from "../components/Home/ValueProposition";
import Venue from "../components/Home/Venue";
import LazyLoad from "react-lazyload";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import CTA from "../components/cta-block";

const Home = ({ data }) => {
  const heroImage = data.heroImage;
  const whyAttendImage = getImage(data.whyAttendImage);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  // Load Window after page mounts
  useEffect(() => {
    toggle();
  }, []);

  return (
    <Layout pageId="home">
      <SEO title="Home" />
      <Hero heroImage={heroImage} />
      {/* 
      <Modal isOpen={modal} toggle={toggle} centered={true} size="lg">
        <ModalBody className="p-5">
          <h3>Xylem Reach21 will be a hybrid in-person and virtual event on October 24-27, 2021.</h3>
          <h6 className="py-3">Whether you join us from New Orleans or from around the world, we’re focused on helping you reach farther. Registration is now open.</h6>
          <h4 className="my-auto" style={{color: "#0085ad"}}>HEALTH & SAFETY UPDATE</h4> 
            <h6 className="py-3">The City of New Orleans announced a vaccine requirement for restaurants, bars and large events that includes Reach21. Conference attendees will be required by the city to show proof of vaccination <strong>OR</strong> a negative result on a COVID-19 PCR test. New Orleans requires the negative test every 72 hours. This means attendees will need a negative result on a COVID-19 PCR test just before they arrive and <strong>a second one</strong> during their time in New Orleans. We will be sharing more details on this requirement soon.
            <br></br><br></br>
            Please bookmark the <a href="/attend/health-safety/" target="_blank">Health & Safety page</a> for timely updates about the developing conditions.
            </h6>
          <div className="d-flex">
            <button className="tertiary-cta" onClick={() => { toggle() }}>Close</button>
            <a className="primary-cta ml-auto" href="https://cvent.me/Yg3nod?RefId=Reach+2021+Registration">Register Now</a>
          </div>
        </ModalBody>
      </Modal>
       */}
      <section className="video">
        <Container>
          <Row>
            <Col sm={12}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "3rem",
                }}
              >
                <h3>Connect. Learn. Grow.</h3>
                <hr
                  style={{
                    margin: "1rem auto",
                  }}
                />
                <p>
                  The Xylem Reach Conference delivers educational content
                  designed to take you and your utility to the next level.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="text-center mt-5">
              <h3>The opportunity to learn is endless.</h3>
              <hr
                style={{
                  margin: "1rem auto",
                }}
              />
              <p>
                The event will include compelling sessions, educational Learning
                Labs, SPAN business meetings, Distributor sessions and much
                more. Join us at the Marriott Marquis, you’ll come away
                empowered to get more out of Xylem technology.
              </p>
            </Col>
          </Row>
          {/* Look Back */}
          <Row>
            <Col xs={12}>
              <CTA ctaId="look-back" />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "3rem",
            }}
          ></Row>
        </Container>
      </section>
      <section className="speedbump why-attend">
        <BgImage
          style={{
            height: "100%",
            width: "100%",
          }}
          image={whyAttendImage}
          className="gatsby-image-wrapper"
        >
          <div className="center">
            <h2>We look forward to seeing you at Reach.</h2>

            <Link className="tertiary-cta" to="/attend/updates/">
              Sign Up for Updates
            </Link>
          </div>
        </BgImage>
      </section>
      <section className="video">
        <Container>
          <Row>
            <Col sm={12}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "3rem",
                }}
              >
                <h3>Welcome to Atlanta, Georgia</h3>
                <hr
                  style={{
                    margin: "1rem auto",
                  }}
                />
                <p>Join us at the Marriott Marquis</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Venue className="py-0" hideIcons={true} />
            </Col>
            <Col
              sm={12}
              style={{
                textAlign: "center",
                marginTop: "0px",
              }}
            >
              <a className="primary-cta" href="/attend/location/">
                Learn More
              </a>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "3rem",
            }}
          ></Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Home;
// Set Global Hero Images
export const pageQuery = graphql`
  query HomeQuery {
    heroImage: file(relativePath: { eq: "AtlantaHero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    whyAttendImage: file(relativePath: { eq: "Reach21-Crowd02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
