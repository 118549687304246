import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Link from "gatsby-link";
import { Container, Row, Col } from "reactstrap";
import ReachArrows from "../ReachArrows/";
import VideoEmbed from "../video";

import "../../Home/Video.scss";
import styled from "styled-components";
//import "./Parallax.js";

const Hero = styled.section`
  height: 100vh;
  width: 100vw;
  color: white;
`;
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  //background: rgba(0, 0, 0, 0.36);
  z-index: 0;
`;
const HeroContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  .hero-container {
    .hero-vid {
      max-width: 350px;
    }
    .headline {
      font-size: 3rem;
    }
    h1 {
      font-size: 2rem;
      margin-bottom: 0.8rem;
    }
    h3 {
      margin-bottom: 0.8rem;
      font-size: 1rem;
    }
    .mainCta {
      z-index: 3;
    }
  }
  @media (min-height: 750px) {
    .hero-container {
      .hero-vid {
        max-width: 100%;
      }
    }
  }
`;

const HomeHero = ({ heroImage }) => {
  heroImage = getImage(heroImage);

  return (
    <Hero>
      <BgImage
        style={{
          height: "100%",
          width: "100%",
        }}
        image={heroImage}
        loading="eager"
        className="homeHeroImg" //Needed for Parallax Targeting
        critical
      >
        <ReachArrows />
        <HeroOverlay />
        <HeroContent>
          <Container className="hero-container mt-5">
            <Row className="mt-5">
              {/* 
              <Col xxl={12} xl={8} lg={6} md={6} sm={12} className="hero-vid mr-lg-auto mt-5 mb-0">
                <VideoEmbed videoId={'585496333?h=f9cae88bc9'} />
              </Col>
              */}
            </Row>
            <Row>
              <Col xl={8} lg={8} md={10} sm={12} className="mr-lg-auto mb-0">
                <h1 className="my-0 headline">
                  Join us for the 2024 <nobr>Xylem Reach</nobr> Conference in{" "}
                  <nobr>Atlanta, Georgia</nobr>
                </h1>
                <h3 className="avenir mt-4 mb-4">
                  Atlanta, Georgia
                  <span className="d-xl-inline mx-3">|</span>
                  October 13-16
                </h3>
                <Link
                  className="primary-cta"
                  href="https://cvent.me/z74Vkl?RefId=reach24-registration"
                  target="_blank"
                >
                  Register Now
                </Link>
                <Link
                  to="/attend/updates/"
                  className="tertiary-cta ml-1 ml-md-3"
                >
                  Sign Up for Updates
                </Link>
                {/* <Link className="primary-cta" to="/attend/updates">Sign Up for Updates</Link> */}
              </Col>
            </Row>
          </Container>
        </HeroContent>
      </BgImage>
    </Hero>
  );
};

export default HomeHero;
